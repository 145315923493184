<template>
  <div class="content-esg">
    <div class="content-esg__wrapper wrapper">
      <div class="wrapper-search">
        <ui-input search :placeholder="$t('search')" v-model="search" @input="updateEsgList"/>
        <div class="wrapper-search__icons">
          <img @click="visibleFilter = !visibleFilter" :src="require(`@/assets/svg/search/${filterColor}-filter.svg`)" alt="">
          <ui-menu v-model="visibleSort">
            <template #activator>
              <img :src="require(`@/assets/svg/search/${sortColor}-sort.svg`)" alt="">
            </template>
            <template #body>
              <div class="sort">
                <p
                    v-for="(item, key) in sortData"
                    :class="['sort__item', { active: item.value === sortBy.value }]"
                    :key="key"
                    @click="changeSort(item)"
                >
                  {{ item.text }}
                </p>
              </div>
            </template>
          </ui-menu>
        </div>
      </div>
      <search-filter
          v-if="visibleFilter"
          @submit="updateEsgList"
          @reset="reset"
      >
        <div class="form__row-select">
          <ui-select
              :label="$t('categories')"
              v-model="filter.esgCategoryId"
              :options="categoryOptions"
          />
          <ui-select
              :label="$t('type-2')"
              v-model="filter.esgTypeId"
              :options="typeOptions"
          />
        </div>
        <div class="form__row-select">
          <ui-select
              :label="$t('subtypes')"
              v-model="filter.esgSubtypeId"
              :options="subtypeOptions"
          />
          <ui-select
              :label="$t('status')"
              v-model="filter.statusEsg"
              :options="statusOptions"
          />
        </div>
      </search-filter>
      <div class="wrapper-body">
        <p v-if="!visibleLoader" class="wrapper-body__title">{{ $t('requests-2', {from: totalEsg, to: totalMetaEsg}) }}</p>
        <ui-loader v-model="visibleLoader" />
        <div v-if="!visibleLoader">
          <div  class="wrapper-body__cards">
            <esg-card v-for="(item, key) in getEsg" :key="key" :data="item" />
          </div>
          <div class="empty" v-if="!getEsg.length">
            {{ $t('empty') }}
          </div>
        </div>
        <ui-button v-if="visibleLoadMore && !visibleLoader" color="white" @click="loadMoreEsg">
          {{ $t('show-more') }}
        </ui-button>
      </div>
    </div>
  </div>
</template>

<script>
import EsgCard from "@/components/template/esg/EsgCard.vue";
import SearchFilter from "@/components/common/SearchFilter.vue";
import UiSelect from "@/components/ui/UiSelect.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
export default {
  name: "ContentEsg",

  components: {
    UiSelect,
    SearchFilter,
    EsgCard,
    UiInput: () => import('@/components/ui/UiInput.vue'),
    UiLoader: () => import('@/components/ui/UiLoader.vue'),
    UiButton: () => import('@/components/ui/UiButton.vue'),
    UiMenu: () => import('@/components/ui/UiMenu.vue'),
  },

  data() {
    return {
      search: '',
      total: 0,
      visibleFilter: false,
      visibleLoader: true,
      visibleSort: false,
      sortBy: {},
      sortData: [
        { text: this.$t('by-name'), value: 'description' },
        { text: this.$t('by-region'), value: 'region' },
      ],
      visibleLoadMore: true,
      totalEsg: '',
      totalMetaEsg: '',
      filter: {
        statusEsg: null,
        esgCategoryId: null,
        esgTypeId: null,
        esgSubtypeId: null
      },
      categoryOptions: [],
      typeOptions: [],
      subtypeOptions: [],
    }
  },


  computed: {
    ...mapGetters(['getEsg', 'esgCategoryId', 'esgTypeId', 'esgSubtypeId', 'getUser']),

    filterColor() {
      return this.visibleFilter ? 'red' : 'black'
    },

    sortColor() {
      return this.visibleSort ? 'red' : 'black'
    },

    statusOptions() {
      if(this.getUser?.role  === 'Moderator' || this.getUser?.role === 'Admin') {
        return [
          {name: this.$t('open-1'), value: 'Accepted'},
          {name: this.$t('closed-1'), value: 'Closed'},
          {name: this.$t('under-moderation'), value: 'Moderation'},
          {name: this.$t('rejected-1'), value: 'Rejected'}
        ]
      } else {
        return [
          {name: this.$t('draft'), value: 'Draft'},
          {name: this.$t('open-1'), value: 'Accepted'},
          {name: this.$t('closed-1'), value: 'Closed'},
          {name: this.$t('rejected-1'), value: 'Moderation'},
        ]
      }
    }
  },

  methods: {
    ...mapActions(['esg', 'esgCategories', 'esgTypes', 'esgSubtypes',]),
    ...mapMutations(['setEsg']),

    callEsgApi () {
      this.visibleLoader = true
      this.visibleLoadMore = true
      this.esg({
        skip: 0,
        take: 20,
        userId: this.getUser?.role === 'Moderator' || this.getUser?.role === 'Admin' ? null : this.getUser?.id,
        status: this.getUser?.role === 'Moderator' || this.getUser?.role === 'Admin' ? ['Moderation', 'Closed', 'Accepted', 'Rejected'] : null
      }).then((res) => {
        this.totalMetaEsg = res.meta.total
        this.totalEsg = res.data.length
        this.visibleLoader = false
        this.setEsg(res.data)
        if(res.data.length !== 20) {
          this.visibleLoadMore = false
        }
      })
    },

    updateEsgList () {
      this.visibleLoader = true
      this.visibleLoadMore = true
      this.esg({
        skip: 0,
        take: 20,
        sort: this.sortBy?.value ? this.sortBy?.value : null,
        order: this.sortBy?.value === 'region' || this.sortBy?.value === 'description' ? 'ASC' : null,
        search: this.search ? this.search : null,
        status: (this.getUser?.role === 'Moderator' || this.getUser?.role === 'Admin') && !this.filter.statusEsg ? ['Moderation', 'Closed', 'Accepted', 'Rejected'] : this.filter.statusEsg?.value ? [this.filter.statusEsg.value] : null,
        esgCategoryId: this.filter.esgCategoryId?.id ? this.filter.esgCategoryId.id : null,
        esgTypeId: this.filter.esgTypeId?.id ? this.filter.esgTypeId.id : null,
        esgSubtypeId: this.filter.esgSubtypeId?.id ? this.filter.esgSubtypeId.id : null,
        userId: this.getUser?.role === 'Moderator' || this.getUser?.role === 'Admin' ? null : this.getUser?.id,
      }).then((res) => {
        this.totalMetaEsg = res.meta.total
        this.totalEsg = res.data.length
        this.visibleLoader = false
        this.setEsg(res.data)
        if(res.data.length !== 20) {
          this.visibleLoadMore = false
        }
      })
    },

    loadMoreEsg () {
      // this.visibleLoader = true
      this.visibleLoadMore = true
      this.esg({
        skip: this.getEsg.length,
        take: 20,
        sort: this.sortBy?.value ? this.sortBy?.value : null,
        order: this.sortBy?.value === 'region' || this.sortBy?.value === 'description' ? 'ASC' : null,
        search: this.search ? this.search : null,
        status: this.getUser?.role === 'Moderator' || this.getUser?.role === 'Admin' ? ['Moderation', 'Closed', 'Accepted', 'Rejected'] : this.filter.statusEsg?.value ? [this.filter.statusEsg.value] : null,
        esgCategoryId: this.filter.esgCategoryId?.id ? this.filter.esgCategoryId.id : null,
        esgTypeId: this.filter.esgTypeId?.id ? this.filter.esgTypeId.id : null,
        esgSubtypeId: this.filter.esgSubtypeId?.id ? this.filter.esgSubtypeId.id : null,
        userId: this.getUser?.role === 'Moderator' || this.getUser?.role === 'Admin' ? null : this.getUser?.id,
      }).then((res) => {
        this.totalEsg += res.data.length
        // this.visibleLoader = false
        this.setEsg(this.$store.state.esgStore.esg.concat(res.data))
        if(res.data.length !== 20) {
          this.visibleLoadMore = false
        }
      })
    },

    reset() {
      this.filter.statusEsg = null
      this.filter.esgCategoryId = null
      this.filter.esgTypeId = null
      this.filter.esgSubtypeId = null
      this.callEsgApi()
    },

    changeSort(val) {
      this.sortBy = val
      this.visibleSort = false
      this.updateEsgList()
    },
  },

  watch: {
    getUser() {
      this.callEsgApi()
    }
  },

  mounted () {
    this.callEsgApi()
    this.esgTypes().then((res) => {
      this.typeOptions = res.data
    })
    this.esgCategories().then((res) => {
      this.categoryOptions = res.data
    })
    this.esgSubtypes().then((res) => {
      this.subtypeOptions = res.data
    })
  }
}
</script>

<style lang="scss" scoped>
.content-esg {
  background: #F5F5F5;
  display: flex;
  min-height: 100vh;
  max-width: 1000px;
  width: 100%;

  @media (max-width: 1200px) {
    gap: 100px;
  }

  @media (max-width: 1080px) {
    gap: 30px;
    flex-direction: column;
  }

  &__title {
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    color: #343432;
    white-space: nowrap;
    cursor: pointer;
  }
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.wrapper-search {
  display: flex;
  align-items: center;
  gap: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #fff;

  @media (max-width: 992px) {
    gap: 20px;
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    padding: 0;
    border: none;
  }

  &__icons {
    display: flex;
    align-items: center;
    gap: 20px;

    @media (max-width: 992px) {
      gap: 10px;
    }

    img {
      cursor: pointer;
    }
  }
}

.wrapper-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 1000px;
  width: 100%;

  &__title {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #9A9A9A;
  }

  &__cards {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 30px;

    @media (max-width: 1200px) {
      grid-template-columns: 1fr 1fr;
      column-gap: 10px;
      row-gap: 20px;
    }
    @media (max-width: 768px) {
      margin-bottom: 10px;
    }
  }
}
.form__row-select{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
</style>