<template>
  <div :class="['ui-select', { error: error, required: requiredField }]"
       tabindex="1" @blur="visibleOptions = false">
    <div v-if="label" class="ui-select__label">{{  error ? errorText || label : label }}</div>
    <div @click="openOptions" :class="['ui-select__inner', { active: visibleOptions }]">
      <div class="ui-select__inner-text">
        {{ value?.name ? value.name : placeholder }}
      </div>
      <img src="@/assets/svg/arrows/select-black.svg" alt="" :class="{'ui-select__inner-arrow': greyArrow}" />
    </div>
    <transition name="slide-bottom">
      <div v-if="visibleOptions" class="ui-select__options">
        <div
            v-for="(item,key) in options"
            :key="key"
            @click="changeValue(item)"
            :class="['ui-select__options-item', { active: value?.name === item.name }]"
        >{{ item.name }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "UiSelect",

  props: {
    value: {
      type: [String, Object],
      default: () => {}
    } ,
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    requiredField: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorText: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    greyArrow: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return{
      visibleOptions: false
    }
  },
  methods: {
    changeValue(val) {
      this.$emit('input', val)
      this.visibleOptions = false
    },
    
    openOptions() {
      if(!this.disabled) {
        this.visibleOptions = !this.visibleOptions
      }
    }
  }
}
</script>


<style lang="scss" scoped>
.ui-select {
  position: relative;
  width: 100%;
  //z-index: 10;
  outline: none;
  user-select: none;

  &.required {

    .ui-select__label:after {
      content: '*';
      color: #E21F1F;
    }
  }

  &.error {

    .ui-select__label {
      color: #E21F1F;
    }

    .ui-select__inner {
      border-color: #E21F1F;
    }
  }

  &__label {
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    color: #343432;
    margin: 0 0 10px 30px;
  }

  &__inner {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #9A9A9A;
    border-radius: 200px;
    padding: 8px 10px 8px 15px;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    color: #343432;
    cursor: pointer;

    img {
      transition: 0.3s;
    }

    &.active {

      img {
        transform: rotate(180deg);
      }
    }

    &-arrow {
      filter: opacity(0.5);
      width: 20px;
    }
  }

  &__options {
    position: absolute;
    top: 71px;
    max-height: 198px;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: #FFFFFF;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    transition: 0.3s;
    padding: 12px 12px 20px;
    overflow-y: auto;
    z-index: 100000;

    &-item {
      font-weight: 400;
      font-size: 12px;
      line-height: 11px;
      color: #9A9A9A;
      cursor: pointer;

      &.active {
        color: #CE2121;
      }

      &:hover {
        color: #CE2121;
      }
    }
  }
}
</style>
