<template>
  <router-link :to="`/esg/${data.id}`" :class="['esg-card', getUser?.role === 'Moderator' || getUser?.role === 'Admin' ? 'moderator-card' :  'card']">
    <div class="card__info">
      <p class="card__info-id">{{ $t('request', {data: data.id}) }}</p>
      <p class="card__info-date">{{ getFullDate(data.createdAt) || null }}</p>
      <span class="card__info-location"><img src="../../../assets/svg/icon-location.svg" alt="" />{{ data.district.region?.name}}</span>
    </div>
    <div class="card__status">
      <div class="chip" :style="{ backgroundColor: getEsgStatus(data.status).color}" />
      <p class="card__status-text">{{ $t(getEsgStatus(data.status).text) }}</p>
    </div>
    <p class="card__location"><img src="../../../assets/svg/icon-location.svg" alt="" />{{ data.district.region?.name}}</p>
    <div class="card__end">
      <p class="card__description" v-if="getUser?.role !== 'Moderator' && getUser?.role !== 'Admin'">{{ data?.name }}</p>
      <p :class="getUser?.role === 'Moderator' || getUser?.role === 'Admin' ? 'card__description-moderator' : 'card__description'">{{ data?.category?.name }}</p>
    </div>
    <div class="card__user-info">
      <p v-if="getUser?.role === 'Moderator' || getUser?.role === 'Admin'">{{ getNickname }}</p>
      <p v-if="getUser?.role === 'Moderator' || getUser?.role === 'Admin'"><img :src="getUserRole(data.createdBy?.role).icon" alt=""/>{{ getUserRole(data.createdBy?.role).text }}</p>
      <div class="card__user-info-end">
        <p v-if="getUser?.role !== 'Moderator' && getUser?.role !== 'Admin'">{{ data?.name }}</p>
        <span :class="getUser?.role === 'Moderator' || getUser?.role === 'Admin' ? 'card__user-info-description-moderator' : 'card__user-info-description'">{{ data?.category?.name }}</span>
      </div>
    </div>
  </router-link>
</template>

<script>
import {getEsgStatus, getFullDate} from '@/utils/general'
import {mapGetters} from "vuex";
import {getUserRole} from "../../../utils/user";
export default {
  name: "EsgCard",
  methods: {
    getEsgStatus,
    getUserRole,

    getUserName(name, surname, middlename) {
      if(name && surname && middlename) {
        return name + ' ' + surname.split('')[0].toUpperCase() + '.' + middlename.split('')[0].toUpperCase() + '.'
      }else {
        return name + ' ' + surname.split('')[0].toUpperCase() + '.'
      }
    },
  },

  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      getFullDate,
    }
  },

  computed: {
    ...mapGetters(['getUser']),

    getNickname() {
      return this.data.createdBy?.role === 'Inspector' || this.data.createdBy?.role === 'Lawyer' ? this.getUserName(this.data.createdBy.lastName, this.data.createdBy.firstName, this.data.createdBy?.middleName) : '' ||
      this.data.createdBy?.role === 'Company' ? this.data.createdBy?.companyName : '' ||
      this.data.createdBy?.role === 'Government' ? this.data.createdBy?.companyName : this.data.createdBy?.role === 'Active' ? this.data.createdBy?.login : ''
    },
  }
}
</script>

<style lang="scss" scoped>
.moderator-card {
  display: grid;
  align-items: center;
  grid-template-columns: 1.5fr 1fr 1.5fr 1.5fr 1fr;
  padding: 27px 40px;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
  border-radius: 200px;
  cursor: pointer;

  @media (max-width: 1000px) {
    grid-template-columns: 1.5fr 1.5fr 1.5fr 1.5fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1.5fr 1.5fr 1.5fr;
    padding: 25px 20px;
  }
}

.card {
  display: grid;
  align-items: center;
  grid-template-columns: 1.5fr 1fr 1.5fr 1.5fr;
  padding: 27px 40px;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
  border-radius: 200px;
  cursor: pointer;

  @media (max-width: 1000px) {
    grid-template-columns: 1.5fr 1.5fr 1.5fr ;
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &-id {
      color: #E21F1F;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;

      @media (max-width: 1200px) {
        font-size: 14px;
        line-height: 17px;
      }
    }

    &-date {
      font-weight: 400;
      font-size: 14px;
      line-height: 120.2%;
      color: #9A9A9A;
      font-family: 'Gotham Pro Regular';

      @media (max-width: 1200px) {
        font-size: 12px;
        line-height: 11px;
        color: #9A9A9A;
      }
    }

    &-location {
      display: none;
      align-items: center;
      gap: 5px;
      color: #1B1A1F;
      font-family: 'Gotham Pro Regular';
      font-size: 14px;
      font-weight: 400;
      line-height: 120.2%;
      margin-left: 40px;

      @media(max-width: 1000px) {
        display: flex;
        margin-left: 0;
      }
    }
  }

  &__status {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 5px;

    .chip {
      width: 10px;
      height: 10px;
      background: #CE2121;
      border-radius: 200px;
    }

    &-round {
      width: 10px;
      height: 10px;
      background: #FD9F32;
      box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
      border-radius: 200px;
    }

    &-text {
      font-weight: 400;
      font-size: 14px;
      line-height: 11px;
      color: #343432;
      font-family: 'Gotham Pro Regular';
    }
  }

  &__description-moderator {
    max-width: 178px;
    width: 100%;
    text-align: center;
    color: #1B1A1F;
    font-family: 'Gotham Pro Regular';
    font-size: 14px;
    font-weight: 400;
    line-height: 120.2%;

    @media (max-width: 768px) {
      display: none;
    }
  }

  &__description {
    width: 100%;
    text-align: start;
    color: #1B1A1F;
    font-family: 'Gotham Pro Regular';
    font-size: 14px;
    font-weight: 400;
    line-height: 120.2%;

    @media (max-width: 768px) {
      display: none;
    }
  }

  &__location {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 5px;
    color: #1B1A1F;
    font-family: 'Gotham Pro Regular';
    font-size: 14px;
    font-weight: 400;
    line-height: 120.2%;
    margin-left: 40px;

    @media(max-width: 1000px) {
      display: none;
      margin-left: 0;
    }
  }

  &__end {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    @media (max-width: 768px) {
      display: none;
    }
  }

  &__user-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
    color: #1B1A1F;

    p {
      text-align: right;
      font-family: 'Gotham Pro Regular';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120.2%;
      display: flex;
      align-items: center;
      gap: 5px;

      img {
        width: 16px;
        height: 16px;
      }
    }

    &-description-moderator {
      display: none;
      max-width: 178px;
      width: 100%;
      color: #1B1A1F;
      font-family: 'Gotham Pro Regular';
      font-size: 14px;
      font-weight: 400;
      line-height: 120.2%;
      text-align: end;

      @media (max-width: 768px) {
        display: inline;
      }

      @media (max-width: 500px) {
        word-break: break-all;
      }
    }

    &-description {
      display: none;
      width: 100%;
      color: #1B1A1F;
      font-family: 'Gotham Pro Regular';
      font-size: 14px;
      font-weight: 400;
      line-height: 120.2%;
      text-align: end;
      word-break: break-all;

      @media (max-width: 768px) {
        display: inline;
      }
    }

    &-end {
      display: none;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      @media (max-width: 768px) {
        display: flex;
      }

      p {
        text-align: center;
      }
    }
  }
}
</style>